import packageInfo from '../../package.json';
import { IEnvironment } from './environment-model';

export const environment: IEnvironment = {
    isProduction: false,
    isServiceWorkerEnabled: true,
    name: 'development',
    apiConfig: {
        baseUrl: 'https://ak-let-api-gateway-development.azure-api.net/lettingapp',
        version: 2,
    },
    auth: {
        authorizationParams: {
            audience: 'https://letting-api-dev.akelius.com/',
            scope: 'openid profile email',
            redirect_uri: window.location.origin,
        },
        cacheLocation: 'localstorage',
        clientId: 'VebmcUGYjYq0ImukXi7JZPCX1nhUMf62',
        connection: 'Username-Password-Authentication',
        domain: 'akelius-dev.eu.auth0.com',
        httpInterceptor: {
            allowedList: [{ uri: 'https://ak-let-api-gateway-development.azure-api.net/*' }],
        },
    },
    intranetImageUploadUrl: 'https://intranet-development.akelius.com/upload/pictures/marketing',
    intranetViewingUnitUrl: 'https://intranet-development.akelius.com/permalink/property-asset',
    googleAnaliticsKey: 'G-34J27FD11Q',
    sentryConfiguration: {
        enabled: false,
        debug: false,
        environment: 'development',
        release: 'lettingapp-' + packageInfo.version,
        ignoreErrors: [
            /Loading chunk (.*) failed/,
            /403/,
            /0 Unknown Error/,
            /Non-Error exception captured/,
            /504 Gateway Timeout/,
        ],
        dsn: 'https://791014abdf8b40b097ea25081f9c0d4b@o143872.ingest.sentry.io/5727564',
    },
    documentServiceTeaserImageUrl:
        'https://ak-let-api-gateway-development.azure-api.net/api/media/property-main-pictures/{costCenterCode}/download/400',
    acmUrl: 'https://construction-{countryCode}-testing.development.azure.akelius.com',
};
